import React, { useEffect, useState } from 'react';
import './Content.css';
import Map from './ContentComponents/Map/Map';
import Sidebar from './ContentComponents/Sidebar/Sidebar';
import { useFetchDataDetail } from '../../hooks/useFetchDataDetail';
import config from '../../config';
import Handlebars from 'handlebars';
import { Item } from '../../types';
import { APIProvider } from '@vis.gl/react-google-maps';

Handlebars.registerHelper(
  'ifCond',
  function (this: any, v1: any, v2: any, options: Handlebars.HelperOptions) {
    if (v1 === v2) {
      return options.fn(true);
    }
    return options.inverse(this);
  },
);

Handlebars.registerHelper(
  'getItem',
  function (data: Item[], propertyName: keyof Item, propertyValue: string) {
    const item: Item | undefined = data.find(
      (item) => item[propertyName] === propertyValue,
    );
    return item;
  },
);

Handlebars.registerHelper('getValueItem', function (item: Item, options: any) {
  const context = options.data.root;
  const measurementUnits = context.measurementUnits;
  if (measurementUnits != 'IMP') {
    if (item.value_sint != -9999) return item.value_sint.toString();
    else return '-';
  } else {
    if (item.value_simp != -9999) return item.value_simp.toString();
    else return '-';
  }
});
Handlebars.registerHelper('getUnitItem', function (item: Item, options: any) {
  const context = options.data.root;
  const measurementUnits = context.measurementUnits;
  if (measurementUnits != 'IMP') {
    return item.units_sint.toString();
  } else {
    return item.units_simp.toString();
  }
});

interface ContentProps {
  measurementUnits: string;
}

const Content: React.FC<ContentProps> = ({ measurementUnits }) => {
  const { apiUrl, apiKey } = config;
  const [isActive, setIsActive] = useState(false);
  const [noData, setNoData] = useState(true);
  const [selectedId, setSelectedId] = useState('0');
  const [selectedNameBuoy, setSelectedNameBuoy] = useState('');
  const {
    dataBuoy,
    lastTimeStamp,
    lastBuoyTimeStamp,
    getRefreshTime,
    logoBuoy,
    fetchLastTimeStamp,
  } = useFetchDataDetail(apiUrl, selectedId);
  const [htmlDetailBouy, setHtmlDetailBouy] = useState<string>('');
  const [timerRunning, setTimerRunning] = useState(false);
  const [bouysLoadMap, setBouysLoadMap] = useState(false);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleToggle = (click: string) => {
    if (click == '') setIsActive(!isActive);
    else if (click == 'MAP') {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };

  const callBuoy = (id: string, name: string) => {
    setSelectedNameBuoy(name);
    setNoData(false);
    setIsActive(true);
    setSelectedId(id);
  };

  useEffect(() => {
    setTimerRunning(false);
    if (getRefreshTime > 0) {
      setTimerRunning(true);
    }
  }, [getRefreshTime]);

  useEffect(() => {
    let intervalId: any;
    if (timerRunning) {
      intervalId = setInterval(() => {
        fetchLastTimeStamp();
      }, getRefreshTime * 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [timerRunning]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      dataBuoy != null &&
      dataBuoy.length > 0 &&
      lastTimeStamp != '' &&
      lastBuoyTimeStamp != ''
    ) {
      const firstItem = dataBuoy[0];
      if (!firstItem) return;
      const fetchTemplate = async () => {
        try {
          const item: Item = firstItem;
          let templateResponse = await fetch(
            'assets/template/template_detail_bouy_id_' + item.id_buoy + '.hbs',
          );
          let templateString = await templateResponse.text();
          if (
            templateString.trim().length === 0 ||
            templateString.includes('404') ||
            templateString.includes('Not Found') ||
            templateString.includes('<!DOCTYPE html>')
          ) {
            // REMOVE DEFAULT TEMPLATE WHILE WE REMOVE IT FROM THE DB
            templateResponse = await fetch(
              'assets/template/template_detail_bouy.hbs',
            );
            templateString = await templateResponse.text();
            console.error(
              'Specific template not found and default template is suppressed.',
            );
            return;
          }

          const template = Handlebars.compile(templateString);
          const generatedContent = template({
            dataBuoy,
            firstItem,
            measurementUnits,
            lastTimeStamp,
            lastBuoyTimeStamp,
            selectedNameBuoy,
            logoBuoy,
          });
          setHtmlDetailBouy(generatedContent);
        } catch (error) {
          console.error('Error fetching template:', error);
        }
      };

      fetchTemplate();
    } else {
      console.log('Data not ready or dataBuoy is empty.');
    }
  }, [dataBuoy, lastTimeStamp, lastBuoyTimeStamp, logoBuoy, measurementUnits]);

  return (
    <div className="wrapper">
      <Sidebar
        onToggle={handleToggle}
        isActive={isActive}
        htmlDetailBouy={htmlDetailBouy}
        noData={noData}
        mobile={windowSize.width < 992}
        bouysLoadMap={bouysLoadMap}
        dataBuoy={dataBuoy}
        measurementUnits={measurementUnits}
        lastTimeStamp={lastTimeStamp}
        lastBuoyTimeStamp={lastBuoyTimeStamp}
        selectedNameBuoy={selectedNameBuoy}
        logoBuoy={logoBuoy}
      />
      <div className={`map  ${isActive ? 'map-close' : ''}`}>
        <APIProvider apiKey={apiKey}>
          <Map callBuoy={callBuoy} setBouysLoadMap={setBouysLoadMap} />
        </APIProvider>
      </div>
    </div>
  );
};

export default Content;
