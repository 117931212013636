import React, { useEffect, useState } from 'react';
import './Map.css';
import { useFetchData } from '../../../../hooks/useFetchData';
import config from '../../../../config';
import {
  AdvancedMarker,
  Map as MapGoogle,
  useMap,
} from '@vis.gl/react-google-maps';

interface MapProps {
  callBuoy: (id: string, name: string) => void;
  setBouysLoadMap: any;
}

interface Buoy {
  id_buoy: string;
  lat: number;
  longi: number;
  name_buoy: string;
}

const Map: React.FC<MapProps> = ({ callBuoy, setBouysLoadMap }) => {
  const { apiUrl } = config;

  const { data, error, loading } = useFetchData(apiUrl);
  const [buoys, setBuoys] = useState<Buoy[]>([]);
  const mapRef = useMap();
  const MAX_ZOOM_LEVEL: number = 6;
  const [zoomMap, setZoomMap] = useState<number>(2);
  const [autoZoomMap, setAutoZoomMap] = useState<boolean>(false);

  const fitBounds = () => {
    if (!mapRef || !buoys.length) {
      return;
    }
    setAutoZoomMap(true);
    const bounds = new window.google.maps.LatLngBounds();
    buoys.forEach(({ lat, longi }) => {
      bounds.extend(new window.google.maps.LatLng(lat, longi));
    });
    mapRef.fitBounds(bounds);
  };

  const changeCenter = (lat: number, lng: number) => {
    if (!mapRef) {
      return;
    }
    mapRef.setCenter({ lat, lng });
  };

  useEffect(() => {
    if (data && !loading) {
      setBuoys(data);
      setBouysLoadMap(true);
    }
  }, [data, loading]);

  useEffect(() => {
    fitBounds();
  }, [buoys, mapRef]);

  useEffect(() => {
    if (autoZoomMap) {
      setAutoZoomMap(false);
      if (buoys.length === 1) {
        mapRef?.setZoom(MAX_ZOOM_LEVEL);
      } else {
        if (zoomMap > MAX_ZOOM_LEVEL) {
          mapRef?.setZoom(MAX_ZOOM_LEVEL);
        }
      }
    }
  }, [zoomMap]);

  if (loading) {
    return <div className="loader"></div>;
  }

  if (error) {
    return <div>Error loading map: {error.message}</div>;
  }

  return (
    <MapGoogle
      defaultZoom={8}
      defaultCenter={
        buoys.length
          ? { lat: buoys[0].lat, lng: buoys[0].longi }
          : { lat: 0, lng: 0 }
      }
      mapId="eolos_map_id"
      mapTypeId="satellite"
      streetViewControl={false}
      mapTypeControl={false}
      zoomControl={true}
      fullscreenControl={false}
      onZoomChanged={() => {
        setZoomMap(mapRef?.getZoom() ?? 0);
      }}
    >
      {buoys.map((buoy: Buoy, index: number) => (
        <AdvancedMarker
          key={index + 'MarkerBuoy'}
          position={{ lat: buoy.lat, lng: buoy.longi }}
          title={buoy.name_buoy}
          onClick={() => {
            callBuoy(buoy.id_buoy + '', buoy.name_buoy);
            changeCenter(buoy.lat, buoy.longi);
          }}
        >
          <img src="assets/img/icon-pointer.png" width={46} height={51} />
        </AdvancedMarker>
      ))}
    </MapGoogle>
  );
};

export default Map;
